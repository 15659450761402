import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { EdaraSetupService } from '@core/services/edara-setup.service';

export const edaraSetupGuard: CanActivateFn = async () => {
    const edaraSetupService = inject(EdaraSetupService);
    const router = inject(Router);
    const isEdaraSetupCompleted = await edaraSetupService.checkEdaraSetupCompleted();
    if (!isEdaraSetupCompleted) {
        router.navigate(['/system/edara-setup']);
    }
    return isEdaraSetupCompleted;
};


