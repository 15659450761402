import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../shared/models/api-response';
import { first, share } from 'rxjs/operators';
import { QueryStringHelper } from '../../shared/helpers/query-string.helper';
import { EdaraSetting } from '../../shared/models/edara-setting';
import { Currency, DexieDbProvider } from '@shared';
import { AppSettingsService } from './app-settings.service';
import { Country, State, District, City } from 'src/app/shared/models/customer-v3';
import { OnlineOfflineService } from './online-offine.service';
import { Observable, firstValueFrom } from 'rxjs';
import { LicenseSubscription } from '@core/models/license-subscription';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  baseUrl = '';
  edaraCoreBaseUrl = '';

  constructor(
    private httpClient: HttpClient,
    private localDbProvider: DexieDbProvider,
    private onlineOfflineService: OnlineOfflineService) {
    this.baseUrl = AppSettingsService.appSettings.edaraApi.baseUrl;
    this.edaraCoreBaseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
    if (this.edaraCoreBaseUrl.endsWith('/')) {
      this.edaraCoreBaseUrl = this.edaraCoreBaseUrl.slice(0, -1);
    }
  }

  checkIfAllowedIP(ipAddress: string) {
    return this.httpClient.get<ApiResponse<boolean>>(this.baseUrl + `v2.0/Common/CheckAllowedIP?ipAddress=${ipAddress}`)
      .pipe(share());
  }

  async getAllSettingsAsync() {
    const query: any = {
      offset: 0,
      limit: 1000
    };
    let response: ApiResponse<EdaraSetting[]> | undefined;
    let edaraSetting: EdaraSetting[] = [];
    do {
      const queryString = new QueryStringHelper().toQueryString(query);
      response = await firstValueFrom(
        this.httpClient.get<ApiResponse<EdaraSetting[]>>(this.baseUrl + 'v2.0/common/settings?' + queryString)
      ).catch(() => { return undefined; });
      if (response && response.status_code === 200) {
        query.offset += query.limit;
        edaraSetting = edaraSetting.concat(response.result);
      }
    } while (response && response.status_code === 200 && response.total_count > query.offset);
    return edaraSetting;
  }

  gettSettingById(settingId: number) {
    return this.httpClient.get<ApiResponse<EdaraSetting>>(this.baseUrl + `v2.0/common/settings/${settingId}`);
  }

  async getSettingsByKeyAsync(module: string, key: string) {
    const response = await firstValueFrom(
      this.httpClient.get<ApiResponse<EdaraSetting>>(this.baseUrl + `v2.0/common/settings/FindByKey/${module}?key=` + key)
    ).catch(() => { return undefined; });
    this.addSettingToLocalDb(response?.result);
    return response;
  }

  private addSettingToLocalDb(edaraSetting?: EdaraSetting) {
    if (edaraSetting) {
      this.localDbProvider.db.settings.put(edaraSetting)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  async getSettingsAsync(query: any): Promise<ApiResponse<EdaraSetting[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<EdaraSetting[]>>(this.baseUrl + 'v2.0/common/settings?' + queryString)
    ).catch(() => { return undefined; });
  }

  async getServerDateTimeAsync(): Promise<ApiResponse<Date> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<Date>>(this.baseUrl + 'v2.0/common/GetDateTime')
    ).catch(() => { return undefined; });
  }

  async countSettingsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.settings.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getSettingByKeyLocalAsync(module: string, key: string): Promise<EdaraSetting> {
    return this.localDbProvider.db.settings
      .where({ module: module, key: key })
      .first((element: EdaraSetting) => {
        return element;
      });
  }

  addSettingsToLocalDb(edaraSettings: EdaraSetting[]) {
    if (edaraSettings && edaraSettings.length > 0) {
      this.localDbProvider.db.settings.bulkPut(edaraSettings)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  //#region Countries

  async getAllCountriesAsync(): Promise<Country[]> {
    let response: Country[];
    const count = await this.countAllCountriesLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      response = await this.getCountriesLocalAsync();
      return response;
    } else {
      response = await this.getCountriesAsync() ?? [];
      this.addCountriesToLocalDb(response);
      return response;
    }
  }

  private async countAllCountriesLocalAsync(): Promise<number> {
    return this.localDbProvider.db.countries.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private async getCountriesLocalAsync(): Promise<Country[]> {
    return this.localDbProvider.db.countries.toArray()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getCountriesAsync(): Promise<Country[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<Country[]>(this.edaraCoreBaseUrl + '/api/Countries')
    ).catch(() => { return undefined; });
  }

  private addCountriesToLocalDb(countries: Country[]) {
    if (countries?.length) {
      this.localDbProvider.db.countries.bulkPut(countries)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  //#endregion

  //#region States

  async getAllStatesAsync(): Promise<State[]> {
    let response: State[];
    const count = await this.countAllStatesLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      response = await this.getStatesLocalAsync();
      return response;
    } else {
      response = await this.getStatesAsync() ?? [];
      this.addStatesToLocalDb(response);
      return response;
    }
  }

  private async countAllStatesLocalAsync(): Promise<number> {
    return this.localDbProvider.db.states.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private async getStatesLocalAsync(): Promise<State[]> {
    return this.localDbProvider.db.states.toArray()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getStatesAsync(): Promise<State[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<State[]>(this.edaraCoreBaseUrl + '/api/States')
    ).catch(() => { return undefined; });
  }

  private addStatesToLocalDb(states: State[]) {
    if (states?.length) {
      this.localDbProvider.db.states.bulkPut(states)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  async getStatesByCountryIdAsync(countryId: number): Promise<State[]> {
    let response: State[];
    response = await this.getStatesLocalByCountryIdAsync(countryId);
    if (response?.length) {
      return response;
    } else {
      response = await this.getStatesServerByCountryIdAsync(countryId) ?? [];
      this.addStatesToLocalDb(response);
      return response;
    }
  }

  private async getStatesLocalByCountryIdAsync(countryId: number): Promise<State[]> {
    return this.localDbProvider.db.states.where('countryId').equals(countryId).toArray()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getStatesServerByCountryIdAsync(countryId: number): Promise<State[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<State[]>(this.edaraCoreBaseUrl + `/api/States/Find?CountryId=${countryId}`)
    ).catch(() => { return undefined; });
  }

  //#endregion

  //#region Cities

  async getAllCitiesAsync(): Promise<City[]> {
    let response: City[];
    const count = await this.countAllCitiesLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      response = await this.getCitiesLocalAsync();
      return response;
    } else {
      response = await this.getCitiesAsync() ?? [];
      this.addCitiesToLocalDb(response);
      return response;
    }
  }

  private async countAllCitiesLocalAsync(): Promise<number> {
    return this.localDbProvider.db.cities.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private async getCitiesLocalAsync(): Promise<City[]> {
    return this.localDbProvider.db.cities.toArray()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getCitiesAsync(): Promise<City[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<City[]>(this.edaraCoreBaseUrl + '/api/Cities')
    ).catch(() => { return undefined; });
  }

  private addCitiesToLocalDb(cities: City[]) {
    if (cities?.length) {
      this.localDbProvider.db.cities.bulkPut(cities)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  async getCitiesByStateIdAsync(stateId: number): Promise<City[]> {
    let response: City[];
    response = await this.getCitiesLocalByStateIdAsync(stateId);
    if (response?.length) {
      return response;
    } else {
      response = await this.getCitiesServerByStateIdAsync(stateId) ?? [];
      this.addCitiesToLocalDb(response);
      return response;
    }
  }

  private async getCitiesLocalByStateIdAsync(stateId: number): Promise<City[]> {
    return this.localDbProvider.db.cities.where('stateId').equals(stateId).toArray()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getCitiesServerByStateIdAsync(stateId: number): Promise<City[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<City[]>(this.edaraCoreBaseUrl + `/api/Cities/Find?StateId=${stateId}`)
    ).catch(() => { return undefined; });
  }

  //#endregion

  //#region Districts

  async getAllDistrictsAsync(): Promise<District[]> {
    let response: District[];
    const count = await this.countAllDistrictsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      response = await this.getDistrictsLocalAsync();
      return response;
    } else {
      response = await this.getDistrictsAsync() ?? [];
      this.addDistrictsToLocalDb(response);
      return response;
    }
  }

  private async countAllDistrictsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.districts.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private async getDistrictsLocalAsync(): Promise<District[]> {
    return this.localDbProvider.db.districts.toArray()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getDistrictsAsync(): Promise<District[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<District[]>(this.edaraCoreBaseUrl + '/api/Districts')
    ).catch(() => { return undefined; });
  }

  private addDistrictsToLocalDb(districts: District[]) {
    if (districts?.length) {
      this.localDbProvider.db.districts.bulkPut(districts)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  async getDistrictsByCityIdAsync(cityId: number): Promise<District[]> {
    let response: District[];
    response = await this.getDistrictsLocalByCityIdAsync(cityId);
    if (response?.length) {
      return response;
    } else {
      response = await this.getDistrictsServerByCityIdAsync(cityId) ?? [];
      this.addDistrictsToLocalDb(response);
      return response;
    }
  }

  private async getDistrictsLocalByCityIdAsync(cityId: number): Promise<District[]> {
    return this.localDbProvider.db.districts.where('cityId').equals(cityId).toArray()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getDistrictsServerByCityIdAsync(cityId: number): Promise<District[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<District[]>(this.edaraCoreBaseUrl + `/api/Districts/Find?CityId=${cityId}`)
    ).catch(() => { return undefined; });
  }

  //#endregion


  //#region Currencies

  async getAllCurrenciesAsync(): Promise<Currency[]> {
    let response: Currency[];
    const count = await this.countAllCurrenciesLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      response = await this.getCurrenciesLocalAsync();
      return response;
    } else {
      response = await this.getCurrenciesAsync() ?? [];
      this.addCurrenciesToLocalDb(response);
      return response;
    }
  }

  private async countAllCurrenciesLocalAsync(): Promise<number> {
    return this.localDbProvider.db.Edara3Currencies.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private async getCurrenciesLocalAsync(): Promise<Currency[]> {
    return this.localDbProvider.db.Edara3Currencies.toArray()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getCurrenciesAsync(): Promise<Currency[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<Currency[]>(this.edaraCoreBaseUrl + '/api/Currencies')
    ).catch(() => { return undefined; });
  }

  addCurrenciesToLocalDb(currencies: Currency[]) {
    if (currencies?.length) {
      this.localDbProvider.db.Edara3Currencies.bulkPut(currencies)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  //#endregion

  getLicenseSubscription(includeCashRegisters: boolean = false): Observable<LicenseSubscription> {
    return this.httpClient.get<LicenseSubscription>(`${this.edaraCoreBaseUrl}/api/Subscription?includeCashRegisters=${includeCashRegisters}`);
  }
}
