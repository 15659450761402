import { AddressState, CustomerAddress } from './customer-address';
import { SyncMetadata } from './sync-metadata';

export class Customer {
    id?: number;
    code?: string;
    name!: string;
    phone?: string;
    mobile?: string;
    email?: string;
    payment_type?: string;
    credit_limit?: number;
    balance?: number;
    shipping_term?: string;
    related_account_id?: number;
    pricing_type?: PricingType;
    external_id?: string;
    customer_addresses: CustomerAddress[] = [];
    syncMetadata?: SyncMetadata;
    isSelected?: boolean;
    mobile_parts: string[] = [];

    public get addresses() {
        this.resetAddressesSequence();
        const customerAddresses = this.customer_addresses?.filter(item => item.entity_state !== AddressState.Deleted);
        return customerAddresses;
    }

    public get defaultAddress(): CustomerAddress | undefined {
        if (!this.customer_addresses) {
            return undefined;
        }
        return this.customer_addresses?.find(item => item.is_default === true);
    }

    public static clone(customer: Customer | undefined): Customer | undefined {
        if (!customer) {
            return undefined;
        }

        const newCustomer: Customer = Object.assign(new Customer(), customer);
        if (customer.customer_addresses?.length) {
            newCustomer.customer_addresses = [];
            for (let index = 0; index < customer.customer_addresses.length; index++) {
                newCustomer.customer_addresses.push(CustomerAddress.clone(customer.customer_addresses[index]));
            }
        }
        return newCustomer;
    }

    public setDefaultAddress(addressSequence: number) {
        const defaultAddress = this.customer_addresses?.find(itm => itm.is_default === true);
        if (defaultAddress && defaultAddress.entity_state !== AddressState.Deleted) {
            defaultAddress.is_default = false;
            if (defaultAddress.id) {
                defaultAddress.entity_state = AddressState.Modified;
            }
        }

        const newDefaultAddress = this.customer_addresses?.find(itm => itm.sequence === addressSequence);
        if (newDefaultAddress) {
            newDefaultAddress.is_default = true;
            if (newDefaultAddress.id) {
                newDefaultAddress.entity_state = AddressState.Modified;
            }
        }
    }

    public addAddress(address: CustomerAddress) {
        if (this.addresses.length === 0) {
            address.is_default = true;
        }
        this.customer_addresses?.unshift(address);
        this.resetAddressesSequence();
    }

    public removeAddress(address: CustomerAddress) {
        if (address.id) {
            address.entity_state = AddressState.Deleted;
        } else {
            this.customer_addresses?.splice(address.sequence! - 1, 1);
        }
        this.resetAddressesSequence();

        // If the default address removed, set another address as default 
        if (address.is_default && this.addresses.length > 0) {
            this.setDefaultAddress(this.addresses[0].sequence!);
        }
    }

    public resetAddressesSequence() {
        this.customer_addresses?.forEach(function (customerAddress, index) {
            customerAddress.sequence = index + 1;
        });
    }
}

export enum PricingType {
    EndUser = 'EndUser',
    Dealer = 'Dealer',
    SupperDealer = 'SupperDealer',
    Custom = 'Custom'
}
