/**
 * @component AgGridCommonFilterComponent
 *
 * @description
 * This component manages the filtering functionality for an AG Grid.
 * It allows users to define multiple filter conditions on different columns
 * and applies those filters to the grid.
 *
 * Features:
 * - Add multiple filter conditions on different columns.
 * - Remove existing filter conditions.
 * - Automatically applies the filter conditions to the grid when changed.
 * - Supports various filter types such as text, date, and number.
 * - Logical operators (AND/OR) between filter conditions.
 *
 * @Inputs
 * - gridApi: GridApi - The grid API instance for managing and applying filters.
 *
 * @outputs
 * - This component does not emit outputs directly, but it notifies the `AgGridCommonFilterService` whenever a change is made, which can be used for saving changes externally.
 *
 * @Methods
 * - addCondition(): Adds a new filter condition.
 * - getColumnUsageCount(column: string | undefined): Method to count how many times each column has been selected.
 * - removeCondition(condition: FilterConditions): Removes a specified filter condition.
 * - clearFilter(): Clears all filter conditions.
 * - applyFilter(): Applies the current filter conditions to the grid.
 * - setValidConditions(): Validates and sets the filter conditions.
 * -onDateChange(date: any, cond: FilterConditions, key:'filter'|'filterTo'):
 * - onSelectionColumnChange(event: MatSelectChange, cond: FilterConditions, columnDefs: ColDef[]): Updates the filter type when a column is selected.
 * - isColDef(item: ColDef | ColGroupDef): Checks if an item is a ColDef (column definition).
 *
 * @Usage
 * <app-ag-grid-common-filter
 *   [gridApi]="agGrid"
 *   #filterComponentRef>
 * </app-ag-grid-common-filter>
 *
 * The component provides a user interface with dropdowns for selecting columns, filter types, and filter values.
 * It leverages Angular Material components for a smooth user experience and interacts with the AG Grid API 
 * to apply the filters in real-time.
 */

import { Component, Input } from '@angular/core';
import { GridApi, ColGroupDef, ColDef } from 'ag-grid-community';
import { agGridFilterTypes, agGridOperatorsEnum, FilterConditions, formatDateForAgGrid, getAgGridFilterStructure, OperatorsWithoutInput, updateFilterType } from './ag-grid-common-filter.model';
import { MatSelectChange } from '@angular/material/select';
import { AgFilterColumnOperatorsPipe } from '@shared/pipes/ag-filter-column-operators.pipe';
import { AgGridCommonFilterService } from '@shared/services/ag-grid-common-filter.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-ag-grid-common-filter',
  templateUrl: './ag-grid-common-filter.component.html',
  styleUrls: ['./ag-grid-common-filter.component.scss'],
  providers: [AgFilterColumnOperatorsPipe]
})
export class AgGridCommonFilterComponent {
  @Input() gridApi!: GridApi;
  agGridFilterTypes = agGridFilterTypes;
  conditions: FilterConditions[] = [];
  agGridOperatorsEnum = agGridOperatorsEnum;
  operator: 'AND' | 'OR' = 'AND';
  filterObjChecker: string = '';
  validConditions: FilterConditions[] = [];
  operatorsWithoutInput = OperatorsWithoutInput;

  constructor(
    private agFilterColumnOperators: AgFilterColumnOperatorsPipe,
    private agGridCommonFilterService: AgGridCommonFilterService
  ) { }

  addCondition() {
    const cond: FilterConditions = new FilterConditions();
    cond.operator = this.operator;
    cond.columnDefs = [...((this.gridApi as any)?.columnModel?.columnDefs || [])];
    this.conditions.push(cond);
  }
  getColumnUsageCount(column: string | undefined): number {
    if (column)
      return this.conditions.filter(cond => cond.column === column).length;
    else
      return 0;
  }

  removeCondition(condition: FilterConditions) {
    this.conditions.splice(this.conditions.indexOf(condition), 1);
    this.applyFilter();
  }

  clearFilter() {
    this.conditions.length = 0;
  }

  applyFilter() {
    if (this.gridApi) {
      this.setValidConditions();
      this.gridApi?.setFilterModel(getAgGridFilterStructure(this.validConditions, this.operator));
    }
    this.agGridCommonFilterService.saveChanges$.next(true);
  }

  setValidConditions() {
    this.validConditions = this.conditions.filter((cond: FilterConditions) => new FilterConditions(cond).isValid);
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, cond: FilterConditions, key: 'filter' | 'filterTo') {
    cond[key] = formatDateForAgGrid(event.value);
    this.applyFilter();
  }

  onSelectionColumnChange(event: MatSelectChange, cond: FilterConditions, columnDefs: ColDef[] = []) {
    cond.filter = null;
    cond.filterTo = null;
    updateFilterType(cond, event.value, columnDefs);
    this.applyFilter();
  }

  isColDef(item: ColDef | ColGroupDef): item is ColDef {
    return (item as ColDef).field !== undefined;
  }

  hasPredefinedFilter(columnField: string | null): boolean {
    if (!columnField) return false;
    const columnDef = this.gridApi.getColumnDef(columnField);
    return columnDef?.filterParams?.hasPredefinedFilter || false;
  }

  getPredefinedValuesForColumn(columnField: string | null): any[] {
    if (!columnField) return [];
    const columnDef = this.gridApi.getColumnDef(columnField);
    return columnDef?.filterParams?.predefinedValues || [];
  }

}
