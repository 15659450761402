import { maxBy } from "lodash";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { GridApi } from "ag-grid-community";
import { AgGridCommonFilterComponent } from "../ag-grid-common-filter/ag-grid-common-filter.component";
import { AgGridCommonSortComponent } from "../ag-grid-common-sort/ag-grid-common-sort.component";
import { AgGridCommonFilterViewItem, FilterViewPageNames, SortConditions } from "@shared/models/ag-grid-common-filter.model";
import { FilterConditions } from "../ag-grid-common-filter/ag-grid-common-filter.model";
import { AgGridCommonFilterService } from "@shared/services/ag-grid-common-filter.service";
import { AgGridCommonFieldsComponent } from "../ag-grid-common-fields/ag-grid-common-fields.component";
import { MatSelect } from "@angular/material/select";
import { Subscription } from "rxjs";
import { MatMenuTrigger } from "@angular/material/menu";

@Component({
  selector: "app-ag-grid-common-filter-view",
  templateUrl: "./ag-grid-common-filter-view.component.html",
  styleUrls: ["./ag-grid-common-filter-view.component.scss"],
})
export class AgGridCommonFilterViewComponent implements OnInit, OnDestroy {
  isCreate: boolean = false;
  viewName: string = "";
  selectedViewValue: string | null = null;
  selectedView: AgGridCommonFilterViewItem | null = null;
  viewsList: AgGridCommonFilterViewItem[] = [];
  defaultView: AgGridCommonFilterViewItem = new AgGridCommonFilterViewItem();
  @Input() currentPageName: FilterViewPageNames | null = null;
  @Input() agGridCommonFilterComponentRef!: AgGridCommonFilterComponent;
  @Input() agGridCommonSortComponentRef!: AgGridCommonSortComponent;
  @Input() aGridCommonFieldsRef!: AgGridCommonFieldsComponent;

  @Input() gridApi!: GridApi;
  @Input() gridColumnApi!: GridApi;
  isFocusInput: boolean = false;
  @ViewChild('matSelect') matSelect!: MatSelect;
  subscriptions: Subscription[] = [];
  isSaveChanges: boolean = true;
  isLoaded: boolean = false;
  isSaving: boolean = false;
  editId: any = null;
  @ViewChild(MatMenuTrigger) topMenu!: MatMenuTrigger;

  constructor(private agGridCommonFilterService: AgGridCommonFilterService) {

  }

  ngOnInit(): void {
    this.getAllViews();
    this.saveDefaultView();
    this.changesSubscriptions()

  }

  ngOnDestroy(): void {
    this.agGridCommonFilterService.saveChanges$.next(false)
    this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }

  saveDefaultView() {
    this.defaultView = {
      id: new Date().getTime() + "",
      name: null,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      pageName: this.currentPageName,
      filterConditions: this.agGridCommonFilterComponentRef?.conditions || [],
      sortConditions: this.agGridCommonSortComponentRef?.conditions || [],
      filterOperator: 'AND',
      fieldsAllColumnsState: [],
    };
  }

  getAllViews(withReset: boolean = true) {
    withReset && (this.viewsList = []);
    if (this.currentPageName) {
      this.agGridCommonFilterService.AllFilters$.subscribe(resData => {
        if (resData !== null) {
          this.isLoaded = true;
          let data = resData.filter(item => item.pageName === this.currentPageName);
          this.viewsList = data?.length
            ? data.sort((a, b) => (b?.updatedAt ?? 0) - (a?.updatedAt ?? 0))
            : [];
          if (!data?.length) {
            this.createDefaultView();
          } else {
            if (!this.isLoaded) { // execute with first load only
              this.selectedViewValue = maxBy(data, d => d.lastUsed)?.id ?? data[0].id;
              this.onSelectedViewChange();
            };
            if (!this.selectedViewValue && data?.length) {
              this.selectedViewValue = maxBy(data, d => d.lastUsed)?.id ?? data[0].id;
              this.onSelectedViewChange();
            }
          }
          if (this.selectedViewValue) {
            const selectedView: AgGridCommonFilterViewItem | undefined =
              this.viewsList.find(
                (el: AgGridCommonFilterViewItem) => el.id === this.selectedViewValue
              );
            if (selectedView) {
              this.selectedView = selectedView;
            }
          }
        }


      })
    } else {
      withReset && (this.viewsList = []);
    }
  }

  get isNameTooLong(): boolean {
    return this.selectedView?.name ? this.selectedView.name.length > 15 : false;
  }

  createDefaultView() {
    const item: AgGridCommonFilterViewItem = {
      id: new Date().getTime() + "",
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      name: 'Default View',
      pageName: this.currentPageName,
      filterConditions: [],
      filterOperator: 'AND',
      sortConditions: [],
      fieldsAllColumnsState: [],
    };

    // this.saveViewInItem(item);
    if (item.docId) {
      delete item.docId
    }
    this.agGridCommonFilterService.addAgGridFilterView(item).then(res => {
      this.agGridCommonFilterService.loadAgGridFilterView();
      this.selectedViewValue = item.id;
    });

  }

  createView() {
    if (this.viewName) {
      this.isSaving = true;
      const item: AgGridCommonFilterViewItem = {
        id: new Date().getTime() + "",
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
        name: this.viewName,
        pageName: this.currentPageName,
        filterConditions: [],
        sortConditions: [],
        filterOperator: 'AND',
        fieldsAllColumnsState: [],
      };

      // this.saveViewInItem(item);
      if (item.docId) {
        delete item.docId
      }
      this.agGridCommonFilterService.addAgGridFilterView(item).then(res => {
        this.isCreate = false;
        this.isSaving = false;
        this.agGridCommonFilterService.loadAgGridFilterView();
        this.resetToDefaultView();

        this.selectedViewValue = item.id;
      });

    }
  }

  saveViewChanges() {
    if (!this.selectedView?.id) {
      return
    }
    const item: AgGridCommonFilterViewItem = {
      id: new Date().getTime() + "",
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      name: this.viewName,
      pageName: this.currentPageName,
      filterConditions: this.agGridCommonFilterComponentRef?.conditions || [],
      filterOperator: this.agGridCommonFilterComponentRef.operator || "AND",
      sortConditions: this.agGridCommonSortComponentRef?.conditions || [],
      fieldsAllColumnsState: [],
    };
    const itemDetails: AgGridCommonFilterViewItem | undefined = this.viewsList.find((el) => el.id === this.selectedView?.id);
    const docId: string | undefined = this.viewsList.find((el) => el.id === this.selectedView?.id)?.docId || undefined;
    if (itemDetails && itemDetails.docId) {
      item.id = itemDetails.id;
      item.createdAt = itemDetails.createdAt;
      item.name = itemDetails.name;
      this.saveViewInItem(item);
      item.docId = docId;
      this.agGridCommonFilterService.saveAgGridFilterView(item).then(res => {
        this.agGridCommonFilterService.loadAgGridFilterView();
      });
    }

  }

  renameView(viewName: string) {
    if (!viewName) {
      return
    }
    this.updateViewData({ name: viewName, updatedAt: new Date().getTime() })
  }

  updateViewData(data: any = {}) {
    const targetId = this.editId || this.selectedView?.id;
    const itemDetails: AgGridCommonFilterViewItem | undefined = this.viewsList.find((el) => el.id === targetId)
    const docId: string | undefined = this.viewsList.find((el) => el.id === targetId)?.docId || undefined;
    if (itemDetails && itemDetails.docId) {
      this.isSaving = true;
      this.agGridCommonFilterService.updateAgGridFilterView({
        ...data,
        id: itemDetails.id,
        docId: docId,
      }).then(res => {
        this.agGridCommonFilterService.loadAgGridFilterView();
        if (itemDetails.id === this.selectedViewValue && this.selectedView) {
          this.selectedView.name = data?.name || itemDetails.name
        }
        this.isSaving = false;
        this.isCreate = false;
      });
    }
  }

  deleteAgGridFilterView(id: any) {
    const docId: string | undefined = this.viewsList.find((el) => el.id === id)?.docId || undefined;
    if (!docId) {
      return
    }
    this.agGridCommonFilterService.deleteAgGridFilterView(docId).then(res => {
      if (this.selectedViewValue === id) {
        this.onClickItem(this.viewsList.filter(x => x.id !== id)[0]);
        this.agGridCommonFilterService.loadAgGridFilterView();
      } else {
        this.agGridCommonFilterService.loadAgGridFilterView();
      }
    });
  }

  saveViewInItem(item: AgGridCommonFilterViewItem) {
    if (!item) {
      return
    }
    if (this.gridColumnApi) {
      const fieldsAllColumnsState: any[] =
        this.gridColumnApi.getColumnState() || [];
      item.fieldsAllColumnsState = fieldsAllColumnsState.map((el: any) => {
        return {
          ...el,
          colRef: null,
        };
      });
    }

    //  reset columnDefs for main filter
    if (item.filterConditions?.length) {
      item.filterConditions = item.filterConditions.map((el) => {
        return { ...el, columnDefs: [] };
      });
    }

    //  reset columnDefs for main filter
    if (item.sortConditions?.length) {
      item.sortConditions = item.sortConditions.map((el) => {
        return {
          ...el,
          columnDefs: [],
          isValid: el.isValid ?? true  // Ensure isValid is included with a default value if missing
        } as SortConditions;
      });
    }
  }

  resetToDefaultView() {
    this.selectedViewValue = null;
    this.selectedView = { ...this.defaultView };
    this.agGridCommonFilterComponentRef.conditions.length = 0;
    this.agGridCommonFilterComponentRef.validConditions = [];
    this.agGridCommonSortComponentRef.conditions.length = 0;
    this.gridApi?.setFilterModel({});
    this.gridColumnApi.resetColumnState();
    this.aGridCommonFieldsRef.reset();
  }

  onSelectedViewChange() {
    // prevent auto save data in firebase
    this.isSaveChanges = false;
    // if (event.value === "1") {
    //   this.resetToDefaultView();
    //   return
    //  }
    // if (event.value !== "create") {
    const selectedView: AgGridCommonFilterViewItem | undefined =
      this.viewsList.find(
        (el: AgGridCommonFilterViewItem) => el.id === this.selectedViewValue
      );
    if (selectedView) this.selectedView = selectedView;
    this.applySavedView();

    // }
    // enable auto save data in firebase
    setTimeout(() => { this.isSaveChanges = true; }, 300);
  }

  applySavedView() {
    if (this.selectedView) {
      this.applyMainFilter();
      this.applyMainSort();
      this.applyColsVisibility();
    }
  }

  // apply main filter
  applyMainFilter() {
    if (
      this.selectedView?.filterConditions &&
      this.agGridCommonFilterComponentRef
    ) {
      let conditions: any[] =
        this.selectedView.filterConditions || [];
      conditions = conditions.map((el: FilterConditions) => {
        return {
          ...el,
          columnDefs: [
            ...((this.gridApi as any)?.columnModel?.columnDefs || []),
          ],
        };
      });
      this.agGridCommonFilterComponentRef.conditions = conditions;
      this.agGridCommonFilterComponentRef.operator = this.selectedView.filterOperator || "AND";
      this.agGridCommonFilterComponentRef.applyFilter();
    }
  }

  // apply main sort
  applyMainSort() {
    if (
      this.selectedView?.sortConditions &&
      this.agGridCommonSortComponentRef
    ) {
      let conditions: SortConditions[] = this.selectedView.sortConditions || [];
      conditions = conditions.map((el: SortConditions) => {
        return {
          ...el,
          columnDefs: [
            ...((this.gridApi as any)?.columnModel?.columnDefs || []),
          ],
        } as SortConditions;
      });
      this.agGridCommonSortComponentRef.conditions = conditions;
      this.agGridCommonSortComponentRef.applySort();
    }
  }

  // apply main cols visibility
  applyColsVisibility() {
    if (
      this.selectedView?.fieldsAllColumnsState?.length &&
      this.gridColumnApi
    ) {
      const fieldsAllColumnsState: any[] =
        this.gridColumnApi.getColumnState() || [];
      let fieldsAllColumnsStateFixed: any[] = [];
      this.selectedView?.fieldsAllColumnsState.forEach((el: any) => {
        const col =
          fieldsAllColumnsState.find((elx: any) => elx.colId === el.colId) ||
          {};
        const fixedCol: any = { ...el, ...col };
        fixedCol.colRef = this.gridColumnApi.getColumn(el.colId)?.getColDef();
        fixedCol.hide = el.hide;
        fieldsAllColumnsStateFixed.push(fixedCol);
      });
      this.gridColumnApi.applyColumnState({
        state: fieldsAllColumnsStateFixed,
        applyOrder: true,
      });
      // aGridCommonFieldsRef
      if (fieldsAllColumnsStateFixed?.length > 0) {
        const currentColumnState = this.gridColumnApi.getColumnState() || [];
        this.aGridCommonFieldsRef.allColumnsState = [
          // First include columns from fieldsAllColumnsStateFixed (preserved order)
          ...(fieldsAllColumnsStateFixed || [])
            .filter(el => {
              const column = this.gridColumnApi.getColumn(el.colId);
              if (column) {
                el.colRef = column.getColDef();
                return !!el.colRef?.headerName;
              }
              return false;
            }),
          // Then append any new columns that exist in current state but not in saved state
          ...currentColumnState
            .filter(currentCol =>
              !fieldsAllColumnsStateFixed.some(savedCol => savedCol.colId === currentCol.colId)
            )
            .map(newCol => {
              const column = this.gridColumnApi.getColumn(newCol.colId);
              return {
                ...newCol,
                colRef: column?.getColDef(),
              };
            })
            .filter(col => !!col.colRef?.headerName)
        ];
      }
      this.aGridCommonFieldsRef.checkChanges()
    }
  }

  createNew() {
    // this.matSelect.close();
    this.viewName = '';
    setTimeout(() => {
      this.isCreate = true;
      this.isFocusInput = true;
    }, 1);
  }

  editViewName(item: AgGridCommonFilterViewItem) {
    if (!item) { return }
    this.isFocusInput = true; this.editId = item.id; this.viewName = item.name || '';
    setTimeout(() => {
      this.isCreate = true
    }, 1);
  }

  changesSubscriptions() {
    const saveChanges$ = this.agGridCommonFilterService.saveChanges$.subscribe((res => {
      if (res && this.isSaveChanges) {
        this.saveViewChanges();
      }
    }));
    this.subscriptions.push(saveChanges$);
  }

  onClickItem(item: AgGridCommonFilterViewItem) {
    if (this.selectedViewValue !== item.id) {
      this.selectedViewValue = item.id;
      this.onSelectedViewChange();
      this.agGridCommonFilterService.updateAgGridFilterView({
        ...item,
        id: item.id,
        docId: item.docId,
        lastUsed: Date.now()
      }).then(res => {
        this.agGridCommonFilterService.loadAgGridFilterView();
      });
      if (this.topMenu && this.topMenu.menuOpen) {
        this.topMenu.closeMenu();
      }
    }
  }

}
