export class StockItem {
    id?: number;
    code!: string;
    sku?: string;
    description!: string;
    part_number?: string;
    price!: number;
    dealer_price?: number;
    super_dealer_price?: number;
    purchase_price?: number;
    fifo_cost?: number;
    lifo_cost?: number;
    average_cost?: number;
    last_cost?: number;
    tax_rate?: number;
    tax_id?: number;
    default_unitofmeasure_id?: number;
    unit_of_measures?: UnitOfMeasure[];
    uom_part_numbers: string[] = [];
    description_keywords: string[] = [];
    warehouse_stocks: WarehouseStock[] = [];
    enforce_batch_number_date_entry: boolean = false;
    enforce_expiration_date_entry: boolean = false;
    autofill_batch_number?: boolean;

    stockItemSerial?: StockItemSerial;
    isSelected: boolean = false;

    public static clone(stockItem: StockItem): StockItem {
        return Object.assign(new StockItem(), stockItem);
    }

    /**
     * Get StockItem Cost
     */
    public getCost(valuationCostType: string): number {
        switch (valuationCostType) {
            case 'FIFO':
                return this.fifo_cost ?? 0;
                break;
            case 'LIFO':
                return this.lifo_cost ?? 0;
                break;
            case 'AVERAGECOST':
                return this.average_cost ?? 0;
                break;
            case 'LASTCOST':
                return this.last_cost ?? 0;
                break;
            default:
                return this.average_cost ?? 0;
                break;
        }
    }
}

export interface UnitOfMeasure {
    id: number;
    stock_item_id: number;
    unit_of_measure_id: number;
    uom_part_number: string;
    uom_name: string;
    uom_price: number;
    uom_dealer_price: number;
    uom_super_dealer_price: number;
}

export interface WarehouseStock {
    warehouse_id: number;
    stock_item_id: number;
    balance: number;
    reserved_balance: number;
    batch_number: string;
    production_date: Date;
    expiration_date: Date;
}

export interface StockItemSerial {
    stock_item_id: number;
    warehouse_id: number;
    serial_number: string;
    serial_count: number;
    production_date: Date;
    expiry_date: Date;
}
