import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DexieDbProvider, SystemSetting, SystemSettingsEnum } from '@shared';
import { firstValueFrom, map } from 'rxjs';
import { AppSettingsService } from './app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class EdaraSetupService {

  private _edaraCoreApiBaseUrl: string = '';

  constructor(
    private httpClient: HttpClient,
    private localDbProvider: DexieDbProvider
  ) {
    this._edaraCoreApiBaseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
    if (this._edaraCoreApiBaseUrl.endsWith('/')) {
      this._edaraCoreApiBaseUrl = this._edaraCoreApiBaseUrl.slice(0, -1);
    }
  }

  public async checkEdaraSetupCompleted(): Promise<boolean> {
    const setting = await this.checkEdaraSetupCompletedFromLocal();
    if (setting) {
      return (setting.value === '1' || setting.value === 'true');
    } else {
      const response = await this.checkEdaraSetupCompletedFromServer();
      return response;
    }
  }

  private async checkEdaraSetupCompletedFromLocal(): Promise<SystemSetting> {
    return this.localDbProvider.db.systemSettings
      .where({ name: SystemSettingsEnum.EdaraSetupCompleted })
      .first((element: SystemSetting) => {
        return element;
      });
  }

  private async checkEdaraSetupCompletedFromServer(): Promise<boolean> {
    return firstValueFrom(
      this.httpClient.get<SystemSetting>(this._edaraCoreApiBaseUrl + '/api/systemsettings/FindByName/' + SystemSettingsEnum.EdaraSetupCompleted)
        .pipe(map(res => res ? (res.value === '1' || res.value === 'true') : false))
    ).catch((error) => {
      if (error.status === 404) {
        return false;
      }
      return true;
    });
  }

}
