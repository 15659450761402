import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findItem'
})
export class FindItemInArrayPipe implements PipeTransform {
    transform(array: any[] | null | undefined, searchTerm: string, searchKey: string): any[] {
        if (!array || !searchTerm || !searchKey) return array || [];

        searchTerm = searchTerm.toLowerCase();
        return array.filter(item =>
            item[searchKey]?.toLowerCase().includes(searchTerm)
        );
    }
}
